<template>
  <div>
    <v-dialog persistent
              style="z-index:4000 !important;" max-width="400" width="100%"
              v-model="ordering"
    >
      <v-card>
        <v-card-text class="text-center">

          <h3>{{ $t('erp.lang_orderbonsArePrinted') }}</h3>
          <v-progress-circular
              indeterminate class="mx-auto overflow-hidden"
              color="primary"
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
    <mobile-gastro v-if="this.$vuetify.breakpoint.smAndDown"/>
    <div v-else style="touch-action: manipulation;">

      <!--loading order overlay-->
      <v-overlay
          absolute
          :value="this.loadingOrder"
      >
        <v-card color="white" max-width="400" v-if="this.isConnectionError">
          <v-card-text class="warning--text">
            {{ $t('generic.lang_unableLoadOrder') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn depressed to="/pos/gastro/tables" color="primary">
              {{ $t('generic.lang_gastroPOS_gotoTableOverview') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-progress-circular
            indeterminate
            v-else
            color="primary"
        ></v-progress-circular>
      </v-overlay>

      <HeaderResponsive v-if="$vuetify.breakpoint.smAndDown" :pos-type="posType"/>

      <v-container :disabled="true" fluid>
        <v-layout v-if="$vuetify.breakpoint.mdAndUp">
          <v-flex md7>
            <Grid :pos-type="posType" responsive="0"></Grid>
          </v-flex>

          <v-flex md3>
            <Invoice v-if="!loadingTable" :pos-type="posType"/>
          </v-flex>

          <v-flex md2>
            <Buttons :pos-type="posType"/>
          </v-flex>
        </v-layout>

        <v-layout v-if="$vuetify.breakpoint.smAndDown">
          <v-flex sm12>
            <Grid :pos-type="posType" responsive="1"></Grid>
          </v-flex>
        </v-layout>
      </v-container>

      <Footer v-if="!loadingTable && !$vuetify.breakpoint.smAndDown" :pos-type="posType" :from="oldRoute"/>
      <FooterResponsive v-if="!loadingTable && $vuetify.breakpoint.smAndDown" :pos-type="posType"/>

      <BarcodeKeyboard :pos-type="posType" include-item-booking></BarcodeKeyboard>

      <!-- SHIFT -->
      <StartShiftCounting/>

      <BookItem ref="bookItem" :pos-type="posType"></BookItem>
    </div>
    <!-- Table Lock Dialog -->
    <v-dialog v-model="showTableLockedDialog" max-width="400" persistent>
      <v-card>
        <v-card-title>{{ $t('generic.lang_warning') }}</v-card-title>
        <v-card-text class="text-center">
          {{ $t('generic.lang_tableIsCurrentlyInUse') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="redirect">{{ $t('generic.lang_next') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import Invoice from "../../components/pos/Invoice";
import Buttons from "../../components/pos/Buttons";
import Footer from "../../components/pos/Footer";
import HeaderResponsive from "../../components/pos/HeaderResponsive";
import FooterResponsive from "../../components/pos/FooterResponsive";
import Grid from "../../components/pos/Grid";
import BarcodeKeyboard from "../../mixins/barcodeKeyboard";
import {mapGetters, mapState} from "vuex";
import {ENDPOINTS} from "../../config";
import BookItem from "@/mixins/pos/BookItem";
import MobileGastro from "@/views/pos/mobile/MobileGastro";
import StartShiftCounting from "@/components/pos/StartShiftCounting";
import {Events} from "@/plugins/events";

export default {
  name: "Gastro",

  components: {
    StartShiftCounting,
    MobileGastro,
    BookItem,
    BarcodeKeyboard,
    Grid,
    Footer,
    HeaderResponsive,
    FooterResponsive,
    Buttons,
    Invoice,
  },

  data() {
    return {
      posType: "gastro",
      loadingTable: false,
      showTableLockedDialog: false,
      loadingOrder: false,
      isConnectionError: false,
      oldRoute: null,
      ordering: false,
    }
  },
  beforeMount() {
    if (!this.$store.state.pos.gastro.isDeliveryService && !this.$route.query.hasOwnProperty("reActivateSale")) {
      this.$store.dispatch("pos/setCustomer", {
        posType: this.posType,
        customer: null,
        setCustomerOnly:true,
      })
    }
  
    this.$store.dispatch('pos/gastro/setOrderUUIDS', {});
    this.$forceUpdate()
  },
  beforeDestroy() {
    this.$store.dispatch("pos/setCustomer", {
      posType: this.posType,
      customer: null,
      setCustomerOnly:true,
    });
  
    this.$store.dispatch('pos/gastro/setOrderUUIDS', {});
    this.$store.dispatch('pos/gastro/setServiceTime', null);
    this.$store.dispatch('pos/gastro/setDeliveryService', false);
    this.$forceUpdate()
  },
  computed: {
    ...mapState([
      'api',
      'pos',
      'tse',
      'settings',
    ]),
    ...mapGetters({
      fiscalClient: 'tse/fiscalClient',
      delivery: 'pos/gastro/getDelivery',
      deliveryInfo: 'pos/gastro/getDeliveryInfo',
      itemsTotalPrice: 'pos/gastro/totalPrice',
      openItems: 'pos/gastro/openItems',
      orderUUIDS: 'pos/gastro/orderUUIDS',
      canOrder: 'pos/gastro/canOrder',
    }),
    ...mapGetters('pos/gastro', {
      gastroTableName: 'tableName',
      customer: 'customer',
      currentPartyName: 'currentPartyName',
    }),
  },

  watch:{
    openItems: {
      handler(v){
        if(!this.gastroTableName && !this.$route.query.hasOwnProperty("reActivateSale")){
          this.$store.commit('pos/gastro/cacheDirectSaleItems', v)
        }
      },
      deep: true
    }
  },

  destroyed() {
    Events.$off('createOrder');
    Events.$off('tableScanned');
    Events.$off('ordering');
  },

  mounted() {

    Events.$on('ordering', (status) => {
      this.ordering = status;
    });
    if (!this.$vuetify.breakpoint.smAndDown)
      Events.$on('createOrder', this.getGastroTableFromAPI);
    Events.$on('tableScanned', this.getGastroTableFromAPI);
    this.$store.commit('pos/gastro/setAdditionalStatistic', []);
    this.$store.commit('pos/gastro/setTakeAway', 0);
    this.$store.commit('pos/gastro/setPickup', null);
    this.$store.commit('pos/gastro/setPagerNo', null);
    this.$store.commit("pos/setCustomerDisplayTipPercent", null);
    this.$store.commit('pos/gastro/setPreorderId', null);

    //CLEAR AGE VERIFICATION
    this.$store.commit("pos/setVerifiedAge", 0);
    //CLEAR BOOKED ITEMS
    // if (!this.$route.query.hasOwnProperty("reActivateSale")) {
    //   this.$store.commit("pos/gastro/setOpenItems", {
    //     party: {
    //       name: "Partei 1",
    //     },
    //     orders: []
    //   });
    // }
    if (!this.$route.query.hasOwnProperty('useDeliveryService')) {
      this.$store.dispatch("pos/gastro/setDeliveryService", false)
    } else {
      this.$store.commit('pos/gastro/setTakeAway', 1);
      this.$store.dispatch('pos/gastro/setServiceTime', null);
      this.$store.dispatch('pos/gastro/setForcedVOR', false);
    }
    if (this.posType === "gastro" && !this.$store.getters['pos/gastro/isFromReservation']) {
      //GET BOOKED ITEMS
      if (this.gastroTableName !== null) {
        if (this.gastroTableName !== 0 && !this.$route.query.hasOwnProperty("reActivateSale")) {
          this.getGastroTableFromAPI();
        } else {
          this.loadingTable = false;
        }
      } else {
        this.loadingTable = false;
      }
    } else {
      this.loadingTable = false;
    }


    //CHECK IF TSE CLIENT IS AVAILABLE
    if (this.fiscalClient !== null) {

      if (this.fiscalClient.device.type === null) {
        this.fiscalClient.device.type = "epsonTSE";
      }

      //IF TYPE IS FISKALY
      if (this.fiscalClient.device.type === "epsonTSE") {

        //CHECK IF TSE DEVICE ALREADY ADDED
        let tsePrinters = this.$eposClass.getTSEPrinters();

        if (!tsePrinters.hasOwnProperty(this.fiscalClient.id)) {
          //ADD NEW TSE PRINTER
          this.$eposClass.addTSEPrinter(new this.$epson.ePOSDevice(), {
            id: this.fiscalClient.id,
            ip: this.fiscalClient.device.ip,
            TSEProxyIPAdress: this.fiscalClient.device.TSEProxyIPAdress,
            port: this.fiscalClient.device.port,
            deviceID: this.fiscalClient.device.deviceID,
            adminID: 'Administrator',
            clientID: this.fiscalClient.clientID
          }, false, false, (this.fiscalClient.device.useTSEProxy === 1));
        } else {
          this.tseDevice = this.$eposClass.getTSEPrinter(this.fiscalClient.id);

          if (!this.tseDevice?.tseReady) {
            if (!this.tseDevice.connected) {
              //TRY TO RE-CONNECT!!!
              this.tseDevice.connect();
            }
          }
        }
      }
    }

    //CHECK IF WE HAVE TO VOID AN INVOICE
    if (this.$route.query.hasOwnProperty("voidInvoice")) {
      //GET INVOICE
      this.$refs.bookItem.selectItemEAN("R$" + this.$route.query.voidInvoice);
    }
  },

  sockets: {
    connect: function () {
      this.$socket.emit("tableplan.checkTableLock", (this.pos.gastro && this.pos.gastro.table && this.pos.gastro.table.name) ? this.pos.gastro.table.name : "");
    },
    "tableplan.checkTableLock": function (locked) {
      if (locked) {
        this.showTableLockedDialog = true;
      } else {
        this.$socket.emit("tableplan.setTable", (this.pos.gastro && this.pos.gastro.table && this.pos.gastro.table.name) ? this.pos.gastro.table.name : null);
      }
    },
    "tablebee.orderCreated": function (payload) {
      this.getGastroTableFromAPI();
    },
    "customerdisplay.setTip": function (payload){
      this.$store.commit("pos/setCustomerDisplayTipPercent",payload)
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.pos.gastro.table && !this.showTableLockedDialog && to.path !== "/") {
      this.$socket.emit("tableplan.leaveTable", this.pos.gastro.table.name);
    }

    if (this.$route.query.hasOwnProperty("useDeliveryService"))
      this.$socket.emit("delivery.unlockOrder", this.$route.query.useDeliveryService);

    //clear reservation flag and uuid
    this.$store.commit("pos/gastro/setOrderAsReservation", false);
    this.$store.commit("pos/gastro/setReservation", null);
    this.$store.commit("pos/gastro/setVoidedItems", {});
    this.$store.dispatch('pos/gastro/setForcedVOR', false);
    //CLEAR BOOKED ITEMS
    this.$store.commit("pos/gastro/setOrderedItems", {
      partyName: "Partei 1",
      orders: []
    });
    this.$store.dispatch('pos/gastro/setGastroOrder', null)
    this.pos.gastro.selectedCourse = null;
    next();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.oldRoute = from.name;

    });
  },

  methods: {
    setPartyCustomer(customerID) {
      this.$store.dispatch('pos/gastro/setOrderUUIDSData', {
        partyName: this.currentPartyName,
        data: {
          ...(this.orderUUIDS?.[this.currentPartyName] || {}),
          customer_id: customerID
        },
      });
    },
    redirect() {
      this.$vuetify.breakpoint.smAndDown ? this.$router.replace({name: 'pos.gastro.tresenverkauf'}) : this.$router.replace({name: 'pos.gastro.tables'});
    },
    getGastroTableFromAPI() {
      // this.loadingTable = true;
      let endpoint = ENDPOINTS.POS.GASTRO.ORDERS.GET
      let data = {};

      data.tableName = ((this.gastroTableName === null) ? 0 : this.gastroTableName);
      if (this.pos.gastro.isDeliveryService) {
        endpoint = ENDPOINTS.DELIVERY.ORDER.GET
        if (this.$route.query.hasOwnProperty('useDeliveryService') && this.$route.query.useDeliveryService) {
          data.uuid = this.$route.query.useDeliveryService;
        }
      }

      this.loadingOrder = true;
      this.axios.post(endpoint, data).then((res) => {
        if (res.data.success) {
          let cntr = 0;

          // SET SERVICE TIME

          if (this.pos.gastro.isDeliveryService) {
            this.$store.dispatch('pos/gastro/setServiceTime', res.data.serviceTime);
            this.$store.dispatch('pos/gastro/setForcedVOR', !res.data.isAsap);
          }

          //set the customer if there is one

          if (res.data.customerId && data.tableName == 0) {

            this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETEDIT, {
              customerID: res.data.customerId,
            }).then((res2) => {
              if (res2.data.success) {
                this.$store.dispatch("pos/setCustomer", {
                  posType: this.posType,
                  customer: res2.data.customer,
                  setCustomerOnly: true,
                })
              }
            }).catch((err) => {

            });
          } else if(res.data.customerId && data.tableName != 0){
            this.$root.$emit('updateCustomer')
          } else {
            this.$store.dispatch("pos/setCustomer", {
              posType: this.posType,
              customer: null,
              setCustomerOnly:true,
            })
          }

          /**
           * parties array
           * @type {*[]}
           */
          let parties = [];
          for (let partyName in res.data.orders) {
            ++cntr;

            /**
             * ignore empty parties
             */
            if (cntr <= 1 && !Array.isArray(res.data.orders[partyName])) {
              continue;
            } else if (cntr <= 1) {
              //CLEAR TABLE PARTIES
              //this.$store.commit("pos/gastro/clearTableParties");
            }


            if (!res.data.orders.hasOwnProperty(partyName))
              continue;


            if (res.data.orderUUIDS && res.data.orderUUIDS.hasOwnProperty(partyName)) {
              parties.push({
                name: partyName,
                is_payed: Boolean(res.data.orderUUIDS[partyName].is_paid),
              })
            }
            this.$store.commit("pos/gastro/setOrderedItems", {
              partyName: partyName,
              orders: res.data.orders[partyName]
            });

          }


          //SET PARTY
          //GET FIRST KEY OF PARTIES
          let partyKeys = Object.keys(res.data.orders);
          let party = {
            name: partyKeys[0]
          };
          if (data.tableName !== 0) {
            let tmpParty = this.pos.gastro.table.parties.find(prty => prty.name === partyKeys[0]);
            if (tmpParty)
              party = tmpParty;
          }

          if (!this.pos.gastro.party) {
            this.$store.commit("pos/gastro/changeParty", {
              posType: this.posType,
              party: party
            });
          }

          if (res.data.hasOwnProperty('orderUUIDS')) {
            if (res.data.orderUUIDS && res.data.orderUUIDS.hasOwnProperty(this.pos.gastro.party.name)) {
              this.$store.commit("pos/gastro/setTableParties", parties);
            }
            
            if(!Array.isArray(res.data.orderUUIDS)){
              this.$store.dispatch('pos/gastro/setOrderUUIDS', res.data.orderUUIDS);
            } else {
              this.$store.dispatch('pos/gastro/setOrderUUIDS', {});
            }

            this.$root.$emit('updateCustomer');
          }

          this.$store.dispatch('pos/gastro/setGastroOrder', res.data).then(() => {

            if (res.data.orderFreetext || res.data.freeText) {
              //SET GLOBAL FREETEXT
              this.$store.commit('pos/gastro/setFreetext', res.data.orderFreetext || res.data.freeText, {
                root: true
              });
            }

            if (this.pos.gastro.isDeliveryService && this.$route.query.hasOwnProperty('useDeliveryService') && this.$route.query.useDeliveryService) {
              this.$store.dispatch('pos/gastro/setPickup', res.data.pickUpNo + '');

              this.setPartyCustomer(res.data.customerId);
              this.$root.$emit('updateCustomer');
              
              if (res.data.isDelivery === 1) {
                this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.DELIVERYAREA.GET, {
                  id: res.data.deliveryAreaID
                }).then(res2 => {

                  if (res2.status === 200) {
                    let area = res2.data.formfillData.textFields;
                    area.deliveryAreaID = res.data.deliveryAreaID;
                    this.$store.commit("pos/gastro/setDeliveryInfo", area)
                  }
                }).catch((error) => {
                  this.isConnectionError = true;
                  /* if (error.response) {
                     this.isConnectionError = true;
                   } else if (error.request) {
                     // The request was made but no response was received
                     this.isConnectionError = true;
                   }*/
                }).finally(() => {
                  this.$store.dispatch('pos/gastro/setDelivery', (res.data.isDelivery === 1));

                })

              } else {
                this.$store.dispatch('pos/gastro/setDelivery', (res.data.isDelivery === 1));
              }
            }
          })

        } else {
          this.$store.dispatch('pos/gastro/setGastroOrder', null);
          if (this.pos.gastro.isDeliveryService && this.$route.query.hasOwnProperty('useDeliveryService') && this.customer?.id) {
            this.setPartyCustomer(this.customer?.id);
          }
        }
      }).catch((error) => {
        this.$store.dispatch('pos/gastro/setGastroOrder', null);
        this.isConnectionError = true;
        this.$swal({
          title: this.$t('generic.lang_errorOccurred'),
          text: "",
          icon: "error",
          confirmButtonText: this.$t('generic.lang_refreshPage'),
          showCancelButton: false,
          showConfirmButton: true,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            this.$router.go();
          },
          allowOutsideClick: () => !this.$swal.isLoading,
        });
      }).finally(() => {
        if (this.$route.query.hasOwnProperty('hasMultipleArea') && this.$route.query.hasOwnProperty('useDeliveryService') && !this.deliveryInfo && !this.$route.query.hasOwnProperty("reActivateSale")) {
          this.$root.$emit('showDeliveryAreaDialog')
        }
        this.loadingTable = false;
        this.loadingOrder = false;
      })
    },
  }
}
</script>

<style scoped>
.container {
  padding: 0 !important;
}
</style>

<style>
body, html {
  height: 100% !important;
}
</style>
